$primary: #f26122;
$success: #37af47;
$danger: #e74c3c;
$info: #3498db;
$warning: #f1c40f;
$dark: #111111;
$secondary: #27378f;
$hdModalBg: $dark;

// Thule Store
$hdGradient: #f26122;
$hdSidebarBg: #f26122;
$hdNavBg: #111111;
$hdNavSecondLevelBg: #111111;
$hdNavSecondlLevelColor: #fff;
$hdWidthFull: 220px;
$hdLogoBg: #111111;
$hdHeaderLogoBorder: #111111;
$hdBodyBgLight: #e5e5e5;
$hdPageWrapperBg: #e5e5e5;
$hdPageWrapperColor: #333333;
$hdPageWrapperBodyPosition: static;
$hdPageWrapperHeight: auto;
$hdNavOneLevelBg: #111111;
$hdNavOneLevelBgActive: #e5e5e5;
$hdLoginBg: #4c4c4c;

$tableTrOdd: #fff;
$tableTrEven: #f0f0f0;
$tableHeadColor: #999999;

@import "bootstrap";
@import "react-theme-bootstrap/dist/index.scss";
@import "./Styles/Toastr.scss";
@import "./Styles/ReactPlaceHolder.scss";
@import "react-table-bootstrap/dist/index.scss";

/* Theme Custom */
.form-control {
  border-radius: 10px !important;
}

.form-control::placeholder {
  font-size: 14px;
}

.head-dash-login {
  background: #4c4c4c;
}

.head-dash-login-logo-top {
  max-width: 250px;
}

.head-dash-login
  .head-dash-login-box
  .head-dash-login-content
  .head-dash-login-logo {
  max-width: 250px;
}

.head-dash-login .head-dash-login-box {
  max-width: 400px;
}

.head-dash-login .head-dash-login-box .head-dash-login-content {
  padding: 50px 30px;
}

.head-dash-login-content {
  border-radius: 30px !important;
}

input.form-control-without-border {
  border: 0;
  padding: 0;
  border-bottom: 1px solid #d8d8d8;
  border-radius: 0 !important;
}
input.form-control-without-border:focus {
  border: 0;
  box-shadow: none;
  border-bottom: 1px solid $secondary;
}

input.form-control-without-border:-webkit-autofill,
input.form-control-without-border:-webkit-autofill:hover {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  border: 0;
  -webkit-text-fill-color: inherit;
  box-shadow: 0 0 0px 1000px #e5e5e5 inset;
  transition: background-color 5000s ease-in-out 0s;
}

table tbody tr td {
  border-bottom: 0px !important;
}

table thead th {
  border: 0px !important;
}

.head-dash .nav-left {
  z-index: 4;
}

.head-dash .page-wrapper .page-wrapper-body {
  padding-bottom: 100px;
}

@media (max-width: 992px) {
  .head-dash .nav-left.nav-open-mobile {
    overflow: auto;
  }

  .head-dash .nav-left-content-bg {
    z-index: 3;
  }
}

/* General */
.cur-pointer {
  cursor: pointer;
}

.btn-circle {
  border-radius: 20px;
}
